import { useGetUser } from "src/data/user/hooks/useGetUser"
import { UserRoles } from "src/data/user/user"
import { arrayIncludesValueOfOtherArray } from "src/utils/genericUtil"

export function RequireRoles({
  roles,
  children,
  fallback,
}: {
  roles: UserRoles[]
  children: React.ReactNode
  fallback?: React.ReactNode
}) {
  const user = useGetUser()

  if (!arrayIncludesValueOfOtherArray(user?.roles ?? [], roles)) {
    return <>{fallback}</>
  }

  return <>{children}</>
}
